<template>
  <v-container v-if="scale_error">
    <v-row class="text-center">
      <v-col class="mb-4">
        <div v-if="scale_error">
          <p class="font-weight-bold mb-3">
            Báscula fuera de línea
          </p>
          <v-btn x-large @click="reloadWebsocket">
            Recargar
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'Scale',

    data: () => ({
      data: {},
      ws: null,
      scale_available: true,
      scale_error: false,
      peso_base: 0,
    }),
    created() {
      this.startWebsocket()
    },
    methods: {
      startWebsocket() {
        this.scale_error = false
        let ws = new WebSocket('ws://127.0.0.1:8000/ws')
         ws.onmessage = (e) => {
          this.data = JSON.parse(e.data)
          this.$emit('sendData', this.data)
          if(this.data.error) {
            this.scale_error = true
          }
        }

        ws.onclose = () => {
          ws = null
          this.$emit('sendData', this.data)
          this.scale_error = true
          //console.log('Error', this.scale_error)
          //setTimeout(this.startWebsocket(), 5000)
        }

        ws.onerror = () => {
          ws = null
          console.log('Error')
          this.scale_error = true
          this.$emit('sendData', this.data)
          //setTimeout(this.startWebsocket(), 15000)
        }

        setInterval(() => {
          if(ws) {
            ws.send('echo')
          }
        }, 1000)
      },
      reloadWebsocket() {
        this.startWebsocket()
      }
    }
  }
</script>
