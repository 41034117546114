<template>
  <v-container fluid>
    <v-row class="text-center mb-0" align="center">
      <v-col cols="12" class="pb-0">
        <p class="text-h3 ma-0">Peso de Tilapia</p>
      </v-col>
    </v-row>
    <Scale @sendData="getData"/>
    <v-row class="text-center" align="center" v-if="loading">
      <v-col>
        <v-progress-circular
          :size="50"
          color="black"
          indeterminate
      ></v-progress-circular>
      </v-col>
    </v-row>
    <template v-if="!scale_data.error && !loading">
      <v-row class="text-center" align="center">
        <v-col cols="12">
          <p class="text-h4 mb-0">{{ nombreEstanque }}</p>
          <p class="text-h4 mb-0">Jaba {{ jaba }}</p>
        </v-col>
        <v-col cols="12">
          <p class="text-h2 font-weight-medium">{{ setPesaje }}Kg</p>
        </v-col>
      </v-row>
      <v-row class="text-center">
        <v-col cols="12" sm="6" align="center">
          <p class="text-h3 font-weight-bold mb-0">
            {{ peso_base }}Kg
          </p>
          <p class="caption grey--text">Peso Base</p>
          <v-row justify="center">
            <v-col cols="12" sm="3">
              <v-icon x-large color="warning" v-ripple @click="restart">mdi-file-swap</v-icon>
              <p class="caption">Cambio de Estanque</p>
            </v-col>
            <v-col cols="12" sm="3">
              <v-card class="text-center elevation-2 py-7 " v-ripple @click="save">
                <v-icon x-large color="success">mdi-check-circle</v-icon>
                <p class="caption">Guardar</p>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="6" align="center">
          <v-card 
            width="300"
            class="elevation-0"
          >
            <p class="caption grey--text mb-0">Talla</p>
            <p class="text-h3 mb-0">{{ talla }}</p>
          </v-card>
          <v-card 
            outlined
            height="220" 
            width="280"
            color="orange"
            v-if="setPesaje < 39.5"
          >  
          </v-card>
          <v-card 
            outlined
            height="220" 
            width="280"
            color="green"
            v-else-if="setPesaje >= 39.5 && setPesaje <= 40.5"
          >  
          </v-card>
          <v-card 
            outlined
            height="220" 
            width="280"
            color="red"
            v-else
          >  
          </v-card>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import format from 'date-fns/format'
import Scale from '../components/Scale'
import axios from 'axios'

export default {
  name: 'Pesaje',
  components: {
    Scale
  },
  data() {
    return {
      scale_data: {},
      peso_base: 0,
      talla: '',
      loading: true,
      jaba: '',
      nombreEstanque: null,
      showConfirmation: false
    }
  },
  created() {
    this.peso_base = this.$store.state.peso_base
    this.talla = this.$store.state.talla
    this.jaba = this.$store.getters.getJabaCount
    this.nombreEstanque = this.$store.getters.getNombreEstanque
    axios.get('/api/pesaje/last-jaba/' + this.$store.getters.getCosecha)
    .then(res => {
      console.log('count', res.data)
      if(res.data[0] == undefined) {
        this.jaba = 1
      }
      else {
        this.jaba = Number(res.data[0].field_jaba_pesaje) + 1
      }
    })
    .catch(error => console.log(error))
  },
  methods: {
    getData(val) {
      this.scale_data = val
      this.loading = false
    },
    restart() {
      this.$router.push({'name': 'Estanques'})
    },
    save() {
      //this.loading = true
      this.showConfirmation = false
      this.$store.commit('setEndTime')
      const data = {
        'type': [{"target_id": "pesaje"}],
        'field_cosecha_pesaje': [{"target_id": this.$store.getters.getCosecha}],
        'field_estanque_pesaje': [{"target_id": this.$store.getters.getEstanque}],
        'field_talla_pesaje': [{value: this.talla}],
        'field_jaba_pesaje': [{value: this.jaba}],
        'field_peso_pesaje': [{value: this.setPesaje}],
        'field_fecha_inicial_pesaje': [{value: format(this.$store.getters.getStartTime, "yyyy-MM-dd'T'HH:mm:ss")}],
        'field_fecha_final_pesaje': [{value: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss")}],
      }
      this.$store.commit('setDataToSave', data)
      this.$store.commit('setJabas', {
        'peso_base': this.peso_base,
        'talla': this.talla,
        'peso': this.setPesaje,
        'jaba': this.$store.getters.getJabaCount,
        'start': format(this.$store.getters.getStartTime, 'yyyy-MM-dd HH:mm'),
        'end': format(new Date(), 'yyyy-MM-dd HH:mm'),
      })
      this.$store.commit('setJabaCount')
      this.$router.push({'name': 'ConfirmarPesaje'})
    }
  },
  computed: {
    setPesaje() {
      return this.scale_data.weight - this.$store.getters.getTarado
    }
  }
}
</script>

<style>

</style>