<template>
  <v-app>
    <v-app-bar
      app
      color="black"
      dark
    >
      <div class="d-flex align-center">
      </div>

      <v-spacer></v-spacer>
      <v-btn icon @click="report">
        <v-icon>mdi-view-list-outline</v-icon>
      </v-btn>
      <v-btn icon @click="config">
        <v-icon>mdi-cog</v-icon>
      </v-btn>
      <v-btn
        text
        @click="home"
      >
        <span class="mr-2">BluFish</span>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
  methods: {
    config() {
      this.$router.push({'name': 'Settings'})
    },
    home() {
      this.$router.push({'name': 'Index'})
    },
    report() {
      this.$router.push({'name': 'Reporte'})
    }
  }
};
</script>
