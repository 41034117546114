<template>
  <v-container fluid class="fill-height">
    <v-row class="text-center" justify="center">
      <v-col cols="12">
        <p class="text-h3">Configuración</p>
        <p class="caption grey--text">Sistema de Pesaje</p>
      </v-col>
      <v-col cols="12" sm="6">
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <p class="red--text" v-if="loginError">Error en el usuario o contraseña!</p>
          <v-text-field
            v-model="username"
            :rules="requiredFields"
            label="Usuario"
            required
          ></v-text-field>

          <v-btn
            :disabled="!valid"
            color="black"
            class="mr-4"
            @click="validate"
            text
          >
            Guardar
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Settings',
  data() {
    return {
      valid: true,
      username: '',
      requiredFields: [
        v => !!v || 'Campo requirido',
      ],
    }
  },
  created() {
    this.username = this.$store.getters.getUsername
    console.log('current user', this.currentUser)
  },
  methods: {
    ...mapActions({
      signIn: 'auth/signIn'
    }),
    start() {
      this.$router.push({'name': 'Tarado'})
    },
    validate() {
      if(this.$refs.form.validate()) {
        this.$store.commit('setUsername', this.username)
        this.signIn({
          username: this.username,
          password: this.username
        })
        this.$router.push({name: 'Index'})
      }
    }
  },
  computed: {
    ...mapGetters({
      loginError: 'auth/loginError',
      currentUser: 'auth/user',
    })
  }
}
</script>

<style>

</style>