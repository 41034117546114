<template>
  <v-container>
    <v-row class="text-center" align="center">
      <v-col cols="12">
        <p class="text-h3">Selección de Talla</p>
      </v-col>
    </v-row>
    <v-row class="text-center mt-10" align="center">
      <v-col cols="12" sm="4" align="center">
        <v-card v-ripple @click="setTalla('Chica')">
          <v-card-text>
            <v-img 
              src="../assets/fish.png"
              width="120"
              height="80"
            />
            <p class="text-h4">Chica</p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="4" align="center">
        <v-card v-ripple @click="setTalla('Mediana')">
          <v-card-text>
            <v-img 
              src="../assets/fish.png"
              width="140"
              height="120"
            />
            <p class="text-h3">Mediana</p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="4" align="center">
        <v-card v-ripple @click="setTalla('Grande')">
          <v-card-text>
            <v-img 
              src="../assets/fish.png"
              width="200"
              height="160"
            />
            <p class="text-h2">Grande</p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'SeleccionTalla',
  data() {
    return {
      
    }
  },
  methods: {
    setTalla(val) {
      this.$store.commit('setTalla', val)
      this.$router.push({'name': 'Pesaje'})
    }
  }
}
</script>

<style>

</style>