<template>
  <v-container fluid>
    <v-row class="text-center">
      <v-col cols="12">
        <p class="text-h3 font-weight-medium">Tarado de Jabas</p>
      </v-col>
    </v-row>
    <Scale @sendData="getData" />
    <v-row class="text-center" align="center" v-if="loading">
      <v-col>
        <v-progress-circular
          :size="50"
          color="black"
          indeterminate
      ></v-progress-circular>
      </v-col>
    </v-row>
    <template v-if="!loading">
      <template v-if="!scale_data.error">
        <v-row class="text-center mt-10">
          <v-col cols="12">
            <v-row justify="center" class="my-0">
              <p class="text-h3 font-weight-medium my-0">{{ scale_data.weight }} Kg</p>
            </v-row>
            <v-row justify="center" class="my-0">
              <p class="caption grey--text my-0">Peso</p>
            </v-row>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" sm="3">
            <div class="text-center elevation-2 py-7 " v-ripple @click="setTarado">
              <v-icon x-large color="success">mdi-check-circle</v-icon>
            </div>
          </v-col>
          <v-col cols="12" sm="3">
            <div class="text-center elevation-2 py-7 " v-ripple @click="resetTarado">
              <v-icon x-large color="error">mdi-close-circle</v-icon>
            </div>
          </v-col>
        </v-row>
      </template>
    </template>
  </v-container>
</template>

<script>
  import Scale from '../components/Scale'
  import Number from '../components/Number'

  export default {
    name: 'Tarado',
    components: {
      Scale,
      Number
    },
    data() {
      return {
        weight: 0,
        scale_data: {},
        loading: true,
      }
    },
    created() {
      this.$store.commit('setStartTime')
    },
    methods: {
      getData(val) {
        this.scale_data = val
        this.loading = false
      },
      setTarado() {
        this.$store.commit('setTarado', this.scale_data.weight)
        this.$router.push({'name': 'PesoBase'})
      },
      resetTarado() {
        this.$store.commit('setTarado', 0)
      }
    },
  }
</script>
