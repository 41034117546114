<template>
  <v-container fluid class="fill-height">
    <v-row class="text-center">
      <v-col cols="12">
        <p class="text-h3 font-weight-medium">Peso Base</p>
      </v-col>
    </v-row>
    <v-row class="text-center">
      <v-col cols="12" sm="4">
        <v-row justify="center">
          <p class="text-h3 font-weight-medium" v-if="count.length > 0">{{ parseChars }}Kg</p>
          <p class="text-h3 font-weight-medium" v-else>0.00Kg</p>
        </v-row>
      </v-col>
      <v-col cols="12" sm="8">
        <v-row>
          <v-col cols="12" sm="4">
            <Number number="1" @sendNumber="getNumber" />
          </v-col>
          <v-col cols="12" sm="4">
            <Number number="2" @sendNumber="getNumber" />
          </v-col>
          <v-col cols="12" sm="4">
            <Number number="3"  @sendNumber="getNumber" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4">
            <Number number="4" @sendNumber="getNumber" />
          </v-col>
          <v-col cols="12" sm="4">
            <Number number="5" @sendNumber="getNumber" />
          </v-col>
          <v-col cols="12" sm="4">
            <Number number="6" @sendNumber="getNumber" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4">
            <Number number="7" @sendNumber="getNumber" />
          </v-col>
          <v-col cols="12" sm="4">
            <Number number="8" @sendNumber="getNumber" />
          </v-col>
          <v-col cols="12" sm="4">
            <Number number="9" @sendNumber="getNumber" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="3">
            <Number number="0" @sendNumber="getNumber" />
          </v-col>
          <v-col cols="12" sm="3">
            <Number number="." @sendNumber="getNumber"/>
          </v-col>
          <v-col cols="12" sm="3">
            <div class="text-center elevation-2 py-7 " v-ripple @click="setPesoBase">
              <v-icon x-large color="success">mdi-check-circle</v-icon>
            </div>
          </v-col>
          <v-col cols="12" sm="3" @click="resetPesoBase">
            <div class="text-center elevation-2 py-7 " v-ripple>
              <v-icon x-large color="error">mdi-close-circle</v-icon>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import Scale from '../components/Scale'
  import Number from '../components/Number'

  export default {
    name: 'PesoBase',
    components: {
      Scale,
      Number
    },
    data() {
      return {
        count: []
      }
    },
    methods: {
      getNumber(number) {
        if(this.count.length <= 12) {
          this.count.push(number)
        }
      },
      setPesoBase() {
        if(this.count.length == 0) {
          this.$store.commit('setPesoBase', 0)
        }
        else {
          this.$store.commit('setPesoBase', this.parseChars)
        }
        this.$router.push({'name': 'Estanques'})
      },
      resetPesoBase() {
        this.count = []
      }
    },
    computed: {
      parseChars() {
        return this.count.join("")
      }
    }
  }
</script>
