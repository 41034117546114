<template>
  <v-container fluid>
    <v-row class="text-center mb-0" align="center">
      <v-col cols="12" class="pb-0">
        <p class="text-h3 ma-0">Confirmación de Pesaje</p>
      </v-col>
    </v-row>
    <v-row class="text-center" align="center" v-if="data.length">
      <v-col cols="12">
        <p class="text-h4 mb-0">Pesa {{ data[0].value }}</p>
        <p class="text-h4 mb-0">Jaba {{ data[0].value }}</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'

export default {
  name: 'ConfirmarPesaje',
  data() {
    return {
      data: {}
    }
  },
  created() {
    this.data = this.$store.getters.getDataToSave
    console.log(this.$store.getters.getDataToSave)
  },
  methods: {
    save() {
      axios.post('entity/node?_format=json', this.$store.getters.getDataToSave)
      .then(res => {
        this.$router.push({'name': 'SeleccionTalla'})
        //this.loading = false
        console.log(res.data)
      })
      .catch(error => console.log(error))
    }
  },
  computed: {
    setPesaje() {
      return this.scale_data.weight - this.$store.getters.getTarado
    }
  }
}
</script>