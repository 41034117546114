import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    tarado: 0,
    peso_base: 0,
    talla: '',
    peso: 0,
    peso_acumulado: 0,
    pesaje: [],
    jabas: [],
    jaba_count: 1,
    start_time: null,
    end_time: null,
    username: 'bascula1',
    estanque: null,
    cosecha: null,
    nombreEstanque: null,
    dataToSave: {},
  },
  getters: {
    getTarado(state) {
      return state.tarado
    },
    getPesoBase(state) {
      return state.peso_base
    },
    getTalla(state) {
      return state.talla
    },
    getJabas(state) {
      return state.jabas
    },
    getJabaCount(state) {
      return state.jaba_count
    },
    getStartTime(state) {
      return state.start_time
    },
    getEndTime(state) {
      return state.end_time
    },
    getPesoAcumulado(state) {
      return state.peso_acumulado
    },
    getBaseUrl(state) {
      return state.base_url
    },
    getUsername(state) {
      return state.username
    },
    getEstanque(state) {
      return state.estanque
    },
    getCosecha(state) {
      return state.cosecha
    },
    getNombreEstanque(state) {
      return state.nombreEstanque
    },
    getDataToSave(state) {
      return state.dataToSave
    }
  },
  mutations: {
    setTarado(state, payload) {
      state.tarado = payload
    },
    setPesoBase(state, payload) {
      state.peso_base = payload
    },
    setTalla(state, payload) {
      state.talla = payload
    },
    setJabaCount(state, jabaCount) {
      state.jaba_count = Number(jabaCount) + 1
    },
    setJabas(state, payload) {
      state.jabas.push(payload)
    },
    setStartTime(state) {
      state.start_time = new Date()
    },
    setEndTime(state) {
      state.end_time = new Date()
    },
    setBaseUrl(state, payload) {
      state.base_url = payload
    },
    setUsername(state, payload) {
      state.username = payload
    },
    setCosecha(state, payload) {
      state.cosecha = payload
    },
    setEstanque(state, payload) {
      state.estanque = payload
    },
    setNombreEstanque(state, payload) {
      state.nombreEstanque = payload
    },
    setDataToSave(state, payload) {
      state.dataToSave = payload
    }
  },
  actions: {
  },
  modules: {
    auth,
  }
})
