<template>
  <v-container fluid class="fill-height">
    <v-row class="text-center">
      <v-col cols="12">
        <p class="text-h3">BluFish</p>
        <p class="caption grey--text">Sistema de Pesaje</p>
      </v-col>
      <v-col cols="12">
        <v-btn text x-large @click="start">Iniciar</v-btn>
        <p class="red--text" v-if="loginError">Error en el usuario o contraseña!</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'


export default {
  name: 'Index',
  methods: {
    ...mapActions({
      signIn: 'auth/signIn'
    }),
    start() {
      this.$router.push({name: 'Cosechas'})
    },
    login() {
      this.signIn({
        username: this.$store.getters.getUsername,
        password: this.$store.getters.getUsername
      })
    },
  },
  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
      loginError: 'auth/loginError',
    })
  }
}
</script>

<style>

</style>