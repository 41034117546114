<template>
  <div class="text-center elevation-2 py-5 " v-ripple @click="sendNumber">
    <p class="text-h4 font-weight-medium">{{ number }}</p>
  </div>
</template>

<script>
export default {
  name: 'Number',
  props: ['number'],
  data() {
    return {

    }
  },
  methods: {
    sendNumber() {
      this.$emit('sendNumber', this.number)
    }
  }
}
</script>

<style>

</style>