<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <p class="text-h3 font-weight-medium">Estanques</p>
      </v-col>
    </v-row>
    <v-row class="text-center">
      <v-col cols="12" sm="3" v-for="item in data" :key="item.nid">
        <div class="text-center elevation-2 py-7 " v-ripple @click="select(item.nid, item.title)">
          <p class="title">{{ item.title }}</p>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'Estanques',
    components: {
      
    },
    data() {
      return {
        data: []
      }
    },
    created() {
      axios.get('/api/estanques')
      .then(res => {
        console.log(res.data)
        this.data = res.data
      })
      .catch(error => console.log(error))
    },
    methods: {
      select(nid, title) {
        console.log(nid)
        this.$store.commit('setEstanque', nid)
        this.$store.commit('setNombreEstanque', title)
        this.$router.push({name: 'SeleccionTalla'})
      }
    },
    computed: {
      
    }
  }
</script>
