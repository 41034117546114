import axios from 'axios'

export default {
  namespaced: true,
  state: {
    token: null,
    username: null,
    user: null,
    userData: null,
    login_error: null,
  },
  getters: {
    authenticated(state) {
      return state.token && state.user
    },
    user(state) {
      return state.user
    },
    token(state) {
      return state.token
    },
    loginError(state) {
      return state.login_error
    }
  },
  mutations: {
    SET_TOKEN(state, payload) {
      state.token = payload
    },
    SET_LOGIN_ERROR(state, payload) {
      state.login_error = payload
    },
    SET_USER(state, payload) {
      state.user = payload
    },
    SET_USER_DATA(state, payload) {
      state.userData = payload
    }
  },
  actions: {
    async signIn({ dispatch, commit }, credentials) {
      const data = new FormData();
      data.append("grant_type", "password");
      data.append("client_id", "e2fd3ee8-69e5-4d60-8780-094693c51ab0");
      data.append("client_secret", "basculas");
      data.append("username", credentials.username);
      data.append("password", credentials.password);

      try {
        let response = await axios.post('oauth/token', data, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        })
        //console.log(response.data)
        dispatch('attempt', response.data.access_token)
        commit('SET_LOGIN_ERROR', false)
      } catch (error) {
        console.log(error)
        commit('SET_LOGIN_ERROR', true)
      }
    },
    async attempt({ commit, dispatch }, token) {
      console.log('attempt', token)
      if(token) {
        commit('SET_TOKEN', token)
      }

      try {
        let response = await axios.get('oauth/debug?_format=json')
        commit('SET_USER', response.data)
        //dispatch('getUser', response.data.id)
        console.log('user', response.data)
      } catch (error) {
        commit('SET_TOKEN', null)
        commit('SET_USER', null)
        commit('SET_LOGIN_ERROR', true)
        //console.log(error)
      }
    },
    async getUser(id) {
      console.log(id)
      try {
        let response = await axios.get('user/' + id)
        //commit('SET_USER_DATA', response.data)
        console.log('user data', response.data)
      } catch (error) {
        console.log(error)
      }
    }
  }
}