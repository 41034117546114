<template>
  <v-container>
    <v-row class="text-center" align="center">
      <v-col cols="12">
        <p class="text-h3">Reporte</p>
      </v-col>
    </v-row>
    <v-row justify="end">
      <v-col cols="12" sm="2">
        <v-card class="text-center elevation-2" v-ripple @click="reload">
          <v-card-text>
            Recargar
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="2">
        <v-card class="text-center elevation-2" v-ripple @click="selectEstanque">
          <v-card-text>
            Cambio de Estanque
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="2">
        <v-card class="text-center elevation-2" v-ripple @click="selectTallas">
          <v-card-text>
            Tallas
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-select
          :items="items"
          label="Estanques"
        ></v-select>
      </v-col>
      <v-col cols="12" md="6">
        <v-select
          :items="items"
          label="Tallas"
        ></v-select>
      </v-col>
      <v-col cols="12">
        <v-data-table
          :headers="table_headers"
          :items="data"
          class="elevation-1"
          hide-default-footer
        ></v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Reporte',
  data() {
    return {
      table_headers: [
        { text: 'Jaba', value: 'counter' },
        { text: 'Cosecha', value: 'field_cosecha_pesaje' },
        { text: 'Peso', value: 'field_peso_pesaje' },
        { text: 'Estanque', value: 'field_estanque_pesaje' },
        { text: 'Talla', value: 'field_talla_pesaje' },
      ],
      data: [],
      items: ['A01', 'A02'],
    }
  },
  created() {
    axios.get('api/pesaje')
    .then(res => {
      console.log(res.data)
      this.data = res.data
    })
    .catch(error => console.log(error))
  },
  methods: {
    reload() {
      this.data = []
      axios.get('api/pesaje')
      .then(res => {
        this.data = res.data
      })
      .catch(error => console.log(error))
    },
    selectEstanque() {
      this.$router.push({'name': 'Estanques'})
    },
    selectTallas() {
      this.$router.push({'name': 'SeleccionTalla'})
    }
  },
  computed: {
    
  }
}
</script>

<style>

</style>