import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'
import PesoBase from '../views/PesoBase.vue'
import Tarado from '../views/Tarado.vue'
import Pesaje from '../views/Pesaje.vue'
import SeleccionTalla from '../views/SeleccionTalla.vue'
import Reporte from '../views/Reporte.vue'
import Settings from '../views/Settings.vue'
import Cosechas from '../views/Cosechas.vue'
import Estanques from '../views/Estanques.vue'
import ConfirmarPesaje from '../views/ConfirmarPesaje.vue'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index,
    beforeEnter: (to, from, next) => {
      if(!store.getters['auth/authenticated'] && from.name !== 'Settings') {
        return next({
          name: 'Settings'
        })
      }
      next()
    }
  },
  {
    path: '/peso-base',
    name: 'PesoBase',
    component: PesoBase
  },
  {
    path: '/tarado',
    name: 'Tarado',
    component: Tarado
  },
  {
    path: '/pesaje',
    name: 'Pesaje',
    component: Pesaje
  },
  {
    path: '/seleccion-talla',
    name: 'SeleccionTalla',
    component: SeleccionTalla
  },
  {
    path: '/reporte',
    name: 'Reporte',
    component: Reporte,
    beforeEnter: (to, from, next) => {
      if(!store.getters['auth/token']) {
        return next({
          name: 'Settings'
        })
      }
      next()
    }
  },
  {
    path: '/configuracion',
    name: 'Settings',
    component: Settings,
  },
  {
    path: '/cosechas',
    name: 'Cosechas',
    component: Cosechas
  },
  {
    path: '/estanques',
    name: 'Estanques',
    component: Estanques
  },
  {
    path: '/confirmar-pesaje',
    name: 'ConfirmarPesaje',
    component: ConfirmarPesaje
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
